import {
  AnimalCard,
  Card,
  Flexbox, LoadManager, Messages, PageHeader, Styles, UserCard,
} from '@/components'
import { clinicalCareStatus, clinicalCareStatusClasses } from '@/constants/clinicalCares'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useClinicalCare from '@/hooks/useClinicalCare'
import usePageTitle from '@/hooks/usePageTitle'
import useReadTimelineItem from '@/hooks/useReadTimelineItem'
import dateMask from '@/lib/masks/date'
import phoneNumber from '@/lib/masks/phoneNumber'
import { setClinicalCare } from '@/reducers/clinicalCare'
import clinicalCares from '@/services/api/clinicalCares'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Actions from './components/Actions'
import Events from './components/Events'
import Items from './components/Items'
import Prescritions from './components/Prescritions'
import Records from './components/Records'

export default function Show({ title }) {
  useReadTimelineItem()
  const { id } = useParams()
  usePageTitle({ title: `${title} #${id}` })
  const { state: { clinicalCare }, dispatch } = useClinicalCare()

  const { data, loading, error } = useAxiosRequest(clinicalCares.show, id)

  useEffect(() => {
    if (data) dispatch(setClinicalCare(data))
  }, [data, dispatch])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Atendimento clínico"
        icon="fas fa-stethoscope"
        subtitle={`Código: ${clinicalCare?.uid || '-'}`}
      />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(clinicalCare) && (
            <>
              <Actions clinicalCare={clinicalCare} />
              <div className="my-2">
                Situação:
                <b className={clinicalCareStatusClasses?.[clinicalCare.status]}>
                  &nbsp;{clinicalCareStatus?.[clinicalCare.status] || clinicalCare.status}
                </b>
              </div>
              <div className="my-2">
                Criado em: <b>{dateMask(clinicalCare.createdAt)}</b>
              </div>
              <div className="my-2">
                Protocolo: <b>{clinicalCare.protocolNumber || '-'}</b>
              </div>
              <div className="divider" />
              <div className="my-2">
                Nome da pessoa: <b>{clinicalCare?.extraData?.personName || '-'}</b>
              </div>
              <div className="my-2">
                Fone da pessoa: <b>{phoneNumber(clinicalCare?.extraData?.personPhoneNumber) || '-'}</b>
              </div>
              <div className="divider" />
              <Flexbox.Columns className="mt-2">
                {
                  clinicalCare.animal
                    ? (
                      <>
                        <Flexbox.Column className="col-6 col-sm-12">
                          <AnimalCard animal={clinicalCare.animal} />
                        </Flexbox.Column>
                        <Flexbox.Column className="col-6 col-sm-12">
                          <UserCard title="Tutor / Responsável" user={clinicalCare?.tutor} />
                        </Flexbox.Column>
                      </>
                    )
                    : (
                      <Card.Base>
                        <Card.Body>
                          O pet ainda não foi informado,
                          informe antes de encaminhar para a clínica
                        </Card.Body>
                      </Card.Base>
                    )
                }
              </Flexbox.Columns>

              <div className="divider" />
              <div className="my-2 py-2">
                <UserCard title="Fornecedor" user={clinicalCare.provider} />
              </div>

              <div className="mt-2 pt-2" />
              <h1 className="h5">Observações</h1>
              <div className="pre bg-gray text-small p-2 s-rounded">{clinicalCare.description}</div>
              <div className="divider" />
              <h1 className="h5">Envolvidos</h1>
              <Flexbox.Columns>
                <Flexbox.Column className="col-6 col-sm-12 my-2">
                  <UserCard title="Autor" user={clinicalCare.author} />
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12 my-2">
                  <UserCard title="Clínica" user={clinicalCare.clinic} />
                </Flexbox.Column>
              </Flexbox.Columns>

              <div className="divider" />
              <div className="my-2 py-2">
                <Records records={clinicalCare.records} />
              </div>

              <div className="divider" />
              <div className="my-2 py-2">
                <Items items={clinicalCare.items} price={clinicalCare.price} />
              </div>

              <div className="divider" />
              <div className="my-2 py-2">
                <Prescritions prescritions={clinicalCare.prescritions} />
              </div>

              <div className="divider" />
              <div className="my-2 py-2">
                <h1 className="h5 mb-2">Eventos do atendimento</h1>
                <Events
                  events={clinicalCare?.events}
                  providerCity={clinicalCare?.provider?.city}
                />
              </div>
              <Messages
                addMessage={(body) => clinicalCares.addMessage(id, body)}
                clinicId={clinicalCare.clinicId}
                providerId={clinicalCare.providerId}
                fetchList={() => clinicalCares.messages(id)}
              />
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}
